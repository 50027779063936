.priceByBedWrapper {
  --horizontal-scroll-container-padding: 0;
  padding: var(--space-2x) var(--space-3x) var(--space-0-5x) var(--space-3x);
  width: 100%;
  display: flex;
  margin-bottom: auto;

  &.moveInfoToBottom {
    margin-bottom: unset;
    margin-top: auto;
  }
}

.priceByBedWrapper.withUpdatedStyles {
  font-size: 15px;
  padding: var(--space-none) var(--space-3-5x);
  margin-top: var(--space-4x);
  margin-bottom: auto;
}

.priceByBedCount {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin-right: var(--space-2x);

  &:not(:first-child) {
    padding-left: var(--space-2x);
    border-left: 1px solid var(--color-gray-200);
  }
  &:last-child {
    margin-right: 0;
  }
}

.withUpdatedStyles .priceByBedCount {
  margin-right: var(--space-5x);
  line-height: var(--leading-tight);
  gap: var(--space-1-5x);
  flex: unset; /* Remove when making this permanent */

  &:not(:first-child) {
    padding-left: var(--space-5x);
  }
}

.priceText {
  color: var(--color-black);
  font-weight: var(--font-weight-semibold);
}

.sqFtText {
  white-space: nowrap;
}
